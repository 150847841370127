import React from "react"
import { createUseStyles } from "react-jss"
import { StaticImage } from "gatsby-plugin-image"
import { Content } from "../grid"
import Button from "../interactive/button"
import CtaGroup from "./cta-group"

const useStyles = createUseStyles({
  card: {
    background: "#F8D552",
    width: "100%",
    borderRadius: "1rem",
    padding: "1rem",
  },
  content: {
    position: "relative",
    zIndex: 20,
  },
  lemonTree: {
    position: "absolute",
    zIndex: 0,
    bottom: 0,
  },
  tent: {
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  bush: {
    position: "absolute",
    bottom: 0,
    right: 40,
  },
  "@media (max-width: 1024px)": {
    bush: {
      display: "none",
    },
    tent: {
      display: "none",
    },
    content: {
      marginLeft: "33% !important",
    },
  },
  "@media (max-width: 768px)": {
    lemonTree: {
      display: "none",
    },
    tent: {
      display: "inline-block",
    },
    content: {
      marginLeft: "0 !important",
      marginRight: "20% !important",
    },
  },
})

function CtaBox({ data }) {
  const { Heading: heading, ButtonText, ButtonURL, OpenInNewTab } = data
  const cx = useStyles()

  return (
    <div className={`${cx.card} mob-mt-xxl`}>
      <StaticImage
        src="../../images/terms-of-service/lemon-tree.png"
        alt="tree"
        height="100%"
        className={cx.lemonTree}
      />
      <StaticImage
        src="../../images/terms-of-service/tent.png"
        alt="tent"
        height="100%"
        className={cx.tent}
      />
      <StaticImage
        src="../../images/terms-of-service/orange-bush.png"
        alt="bush"
        height="100%"
        className={cx.bush}
      />
      <div className={`${cx.content} lap-my-md`}>
        <Content className="flex flex--justify-center py-xl mob-ml-none">
          <p className="h3">{heading}</p>
          <CtaGroup>
            <Button color="light" openTab={data.OpenInNewTab} href={ButtonURL}>
              {ButtonText}
            </Button>
          </CtaGroup>
        </Content>
      </div>
    </div>
  )
}

export default CtaBox
